<script lang="ts" setup>
import type { PropType } from 'vue'

export type TVariant = 'Default' | 'Video' | 'Image' | 'Text'

const props = defineProps({
  variant: {
    type: String as PropType<TVariant>,
    default: 'Default'
  }
})
</script>

<template>
  <div v-if="variant === 'Default'" role="status" class="animate-pulse">
    <div class="mb-4 h-2.5 w-48 rounded-full bg-gray-30 dark:bg-gray-50"></div>
    <div class="mb-2.5 h-2 max-w-[360px] rounded-full bg-gray-30 dark:bg-gray-50"></div>
    <div class="mb-2.5 h-2 rounded-full bg-gray-30 dark:bg-gray-50"></div>
    <div class="mb-2.5 h-2 max-w-[330px] rounded-full bg-gray-30 dark:bg-gray-50"></div>
    <div class="mb-2.5 h-2 max-w-[300px] rounded-full bg-gray-30 dark:bg-gray-50"></div>
    <div class="h-2 max-w-[360px] rounded-full bg-gray-30 dark:bg-gray-50"></div>
    <span class="sr-only">Loading...</span>
  </div>

  <div v-else-if="variant === 'Image'" role="status" class="animate-pulse">
    <div
      class="flex h-full w-full items-center justify-center rounded bg-gray-20 dark:bg-gray-20"
    >
      <svg
        class="h-10 w-10 text-gray-20 dark:text-gray-50"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 18"
      >
        <!-- <path
          d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
        /> -->
      </svg>
    </div>
  </div>

  <div v-else-if="variant === 'Video'" role="status" class="animate-pulse">
    <div
      class="flex h-48 w-full items-center justify-center rounded bg-gray-50 dark:bg-gray-20 sm:w-96"
    >
      <svg
        class="h-10 w-10 text-gray-20 dark:text-gray-50"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 16 20"
      >
        <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
        <path
          d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM9 13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2Zm4 .382a1 1 0 0 1-1.447.894L10 13v-2l1.553-1.276a1 1 0 0 1 1.447.894v2.764Z"
        />
      </svg>
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div v-else-if="props.variant === 'Text'" role="status" class="animate-pulse space-y-2.5">
    <div class="flex w-full items-center space-x-2">
      <div class="h-2.5 w-32 rounded-full bg-gray-20 dark:bg-gray-50"></div>
      <div class="h-2.5 w-24 rounded-full bg-gray-20 dark:bg-gray-50"></div>
      <div class="h-2.5 w-full rounded-full bg-gray-20 dark:bg-gray-50"></div>
    </div>
    <div class="flex w-full max-w-[480px] items-center space-x-2">
      <div class="h-2.5 w-full rounded-full bg-gray-20 dark:bg-gray-50"></div>
      <div class="h-2.5 w-full rounded-full bg-gray-20 dark:bg-gray-50"></div>
      <div class="h-2.5 w-24 rounded-full bg-gray-20 dark:bg-gray-50"></div>
    </div>
    <div class="flex w-full max-w-[400px] items-center space-x-2">
      <div class="h-2.5 w-full rounded-full bg-gray-20 dark:bg-gray-50"></div>
      <div class="h-2.5 w-80 rounded-full bg-gray-20 dark:bg-gray-50"></div>
      <div class="h-2.5 w-full rounded-full bg-gray-20 dark:bg-gray-50"></div>
    </div>
    <div class="flex w-full max-w-[480px] items-center space-x-2">
      <div class="h-2.5 w-full rounded-full bg-gray-20 dark:bg-gray-50"></div>
      <div class="h-2.5 w-full rounded-full bg-gray-20 dark:bg-gray-50"></div>
      <div class="h-2.5 w-24 rounded-full bg-gray-20 dark:bg-gray-50"></div>
    </div>
    <div class="flex w-full max-w-[440px] items-center space-x-2">
      <div class="h-2.5 w-32 rounded-full bg-gray-20 dark:bg-gray-50"></div>
      <div class="h-2.5 w-24 rounded-full bg-gray-20 dark:bg-gray-50"></div>
      <div class="h-2.5 w-full rounded-full bg-gray-20 dark:bg-gray-50"></div>
    </div>
    <div class="flex w-full max-w-[360px] items-center space-x-2">
      <div class="h-2.5 w-full rounded-full bg-gray-20 dark:bg-gray-50"></div>
      <div class="h-2.5 w-80 rounded-full bg-gray-20 dark:bg-gray-50"></div>
      <div class="h-2.5 w-full rounded-full bg-gray-20 dark:bg-gray-50"></div>
    </div>
    <span class="sr-only">Loading...</span>
  </div>
</template>
